import React, { useState, useEffect } from "react";
import "./gallerysection.css";
// import StringData from "../../String.json";
import { Data, siteIndex } from "../../WebsiteData";
const UsedData = Data[siteIndex];

const GallerySection = () => {
  const [data, setData] = useState(UsedData.gallerysection.gallerydata);
  useEffect(() => {
    if (
      UsedData &&
      UsedData.gallerysection &&
      UsedData.gallerysection.gallerydata
    ) {
      setData(UsedData.gallerysection.gallerydata);
    }
  }, [data]);
  return (
    <>
      <div className="container-fluid mt-5 p-0">
        <div class="image-gallery-container">
          <div class="image-gallery">
            {data.map((items) => (
              <div key={items.galleryId} class="image-item">
                <img src={items.galleryImage} alt="seed quality" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GallerySection;
