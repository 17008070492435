import React from "react";
import "./servicesection.css";
import ServiceCard from "./ServiceCard";
// import StringData from "../../String.json";
import { Data, siteIndex } from "../../WebsiteData";
const UsedData = Data[siteIndex];

const ServiceSection = () => {
  return (
    <>
      <div className="container-fluid service-main">
        <div className="container">
          <div className="top-icon">
            <div className="hr-line"></div>
            <div className="circle">
              <img src={UsedData.servicesection.icon} alt="seeds" />
            </div>
            <div className="hr-line"></div>
          </div>
          <div className="service-content">
            <div className="service-text">
              <h1>Our Best Services</h1>
              <p>{UsedData.servicesection.description}</p>
            </div>
            <div className="service-card mt-5">
              <ServiceCard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceSection;
