import React, { useState } from 'react';
import './knowyourmeat.css';
import MeatReport from './MeatReport';
import axios from 'axios';
import { Data, siteIndex } from '../../WebsiteData';
import GoToTop from '../../GoToTop/GoToTop';

const UsedData = Data[siteIndex];

const KnowYourMeat = () => {
  const [searchId, setSearchId] = useState('');
  const [animalData, setAnimalData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true); // Set loading to true when the request starts
    try {
      const response = await axios.get(`https://slaughter-house.muktinathlivestockbank.com.np/api/v1/animal/list?animal_id=${searchId}&slaughterFlag=1`);
      
      console.log('Response status code:', response.status);
     
      if (response.status === 200) {
        const data = response.data.details;
        
        if (data.id === parseInt(searchId, 10)) {
          setAnimalData(data);
          setError('');
        } else {
          setAnimalData(null);
          setError('No matching animal ID found');
        }
      } else {
        setAnimalData(null);
        console.error('Record not found');
      }
    } catch (err) {
      setAnimalData(null);
      setError('Record not found',err);
    }
    finally {
      setLoading(false); // Set loading to false when the request ends
    }
  };

  const linearGradient = 'linear-gradient(148deg, rgba(28,28,28,0.60) 0%, rgba(28,28,28,0.60) 100%)';
  const backgroundImageUrl = `url('${UsedData.servicepage.heroImage}')`;
  const backgroundImg = {
    background: `${linearGradient}, ${backgroundImageUrl} no-repeat center center / cover`,
    backgroundSize: 'cover'
  };

  return (
    <>
      <div className="container-fluid knowmeat" style={backgroundImg}>
        <div className="container knowmeat-content">
          <div className="row knowmeat-logo">
            <div className="col">
              <img src={UsedData.servicepage.nepalLogo} alt="nepal-logo" />
            </div>
            <div className="col">
              <img src={UsedData.servicepage.mkclLogo} alt="mkcl-logo" />
            </div>
            <div className="col">
              <img src={UsedData.servicepage.butwalLogo} alt="butwal-logo" />
            </div>
          </div>
          <div className="knowmeat-header">
            <h1>BUTWAL SLAUGHTER HOUSE</h1>
            <p>Butwal, Nepal</p>
          </div>
          <div className="col-md-6 knowmeat-search mt-4">
            <input
              type="number"
              className="col-md-4 form-control"
              placeholder="Enter Animal ID"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
            />
            <button className="col-md-4 btn btn-primary" type="button" onClick={handleSearch}>
              Show Details
            </button>
          </div>
        </div>
      </div>

      <div className="mt-5 mb-5">
        
        {loading && <p>Loading...</p>}
        {error && <p className="alert alert-danger" role="alert">{error}</p>}

        {/* <pre>{JSON.stringify(animalData, null, 2)}</pre> */}
        
        {animalData && animalData.meat_inspec !== null ? (
          <MeatReport animalData={animalData} />
        ) : (
          animalData && <p className="alert alert-warning" role="alert">Animal is not slaughtered yet.</p>
        )}
      </div>
      <GoToTop/>
    </>
  );
};

export default KnowYourMeat;
