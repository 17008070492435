import React from "react";
import "./initiationcard.css";
import { Link } from "react-router-dom";
// import StringData from "../../String.json";
import { Data, siteIndex } from "../../WebsiteData";
const UsedData = Data[siteIndex];
const InitiationCard = () => {
  return (
    <>
      <div className="container initiation-card">
        <div className="row initiation-content">
          <div className="col-md-6 seedtest-img">
            <img
              src={UsedData.initiationcard.cardImage}
              alt="Seed quality control center"
            />
          </div>
          <div className="col-md-6 seedtest-text">
            <h2>{UsedData.initiationcard.title}</h2>
            <p>{UsedData.initiationcard.description}</p>

            <Link to="/services" href="../../Pages/ServicePage/ServicePage.js">
              <button className="btn btn-success">Know Your Meat</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default InitiationCard;
