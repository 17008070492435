import React from 'react'
import "./meatreport.css"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Data, siteIndex } from "../../WebsiteData";


const UsedData = Data[siteIndex];

const MeatReport = ({animalData}) => {
     // Calculate the total meat weight
     const totalMeatWeight = (
        parseFloat(animalData.meat_details.carcass_weight) +
        parseFloat(animalData.meat_details.head_weight) +
        parseFloat(animalData.meat_details.liver_heart_weight) +
        parseFloat(animalData.meat_details.legs) +
        parseFloat(animalData.meat_details.lungs_weight)
    ).toFixed(2);

    const handleDownload = () => {      
    const input = document.getElementById('report');
    html2canvas(input, { scale: 1 }).then((canvas) => {  // Reduced scale
      const imgData = canvas.toDataURL('image/jpeg', 0.75);  // Reduced quality
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 10;  // Centering the image
      const imgY = (pdfHeight - imgHeight * ratio) / 10;
      pdf.addImage(imgData, 'JPEG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save("meatreport.pdf");
    });
      };

    //Animal health status
    const renderAlert = () => {
        const alertStyle = {
            width: '250px',  // Set your desired width
            height: '50px', // Set your desired height
        };
        switch (animalData.health_status) {
            case 'Healthy':case 'healthy':
                return <div className="alert alert-success d-flex justify-content-between align-items-center" role="alert" style={alertStyle}>{animalData.health_status}</div>;
            case 'Unhealthy':case 'unhealthy':
                return <div className="alert alert-danger" role="alert" style={alertStyle}>{animalData.health_status}</div>;
            case 'Quarantined':case 'quarantined':
                return <div className="alert alert-warning" role="alert" style={alertStyle}>{animalData.health_status}</div>;
            default:
                return null;
        }
    };

      
    

  return (
   <>
   <div className='container-fluid meatreport' id="report">
    <div className='container meatreport-content'>
        <div className='meatreport-logo mt-4'>
            <img src={UsedData.site.logo} alt="muktinathlivestockbank"/>
        </div>
        <div className='meatreport-title mt-5'>
            <h1><span>MEAT</span> HEALTH REPORT</h1>
        </div>

        {/* General Information */}
        <div className='col-md-12 general-information  mt-5 pb-4 border-top-0'>
            <div className='meatreport-subheader'>
                <p>General Information</p>
            </div>
            <div className='data-content mt-4'>
                <h2>Animal ID : {animalData.id}  </h2>
                <div className='row meatreport-data mt-4'>
                    <p className='col-lg-4 col-md-6 col-12'><span>Purchase Date :</span><br/> {animalData.purchased_date}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Origin location of Goat :</span><br/> {animalData.origin_local_body}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Origin district :</span><br/> {animalData.origin_district}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Animal Health Status :</span><br/> {renderAlert()}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Animal Type :</span><br/> {animalData.animal_type}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Purchased Weight (KG) :</span><br/> {animalData.purchased_weight}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Farm Entry Date :</span><br/> {animalData.farm_entry_date}</p>
                </div>
                
            </div>
        </div>
        {/* Processing Details */}
        <div className='col-md-12 processing-detail mt-5 pb-4 border-top-0'>
            <div className='meatreport-subheader'>
                <p>Processing Details</p>
            </div>
            <div className='data-content mt-4'>
                <div className='row meatreport-data mt-4'>
                    <p className='col-lg-4 col-md-6 col-12'><span>Processing Date :</span><br/> {animalData.slaughter_details.date}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Ante-mortem Inspection :</span><br/> {animalData.slaughter_details.antemortem_inspection}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Post-mortem Inspection :</span><br/> {animalData.slaughter_details.postmortem_inspection}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Condemned Part :</span><br/> {animalData.slaughter_details.condemned_part}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Approval for Consumption :</span><br/> {animalData.slaughter_details.approval_for_consumption}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Meat Condition :</span><br/> {animalData.slaughter_details.meat_condition !== null ? animalData.slaughter_details.meat_condition : "null"}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Meat Inspector :</span><br/> Dr. {animalData.slaughter_details.meat_inspector !== null ? animalData.slaughter_details.meat_inspector : "null"}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>NVC Reg. No. :</span><br/> {animalData.slaughter_details.mvc_regsitration_no}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Processing Location :</span><br/> {animalData.slaughter_details.processing_location}</p>
                </div>   
            </div>
        </div>
        {/* Meat Details */}
        <div className='col-md-12 meat-detail mt-5 pb-4 border-top-0'>
            <div className='meatreport-subheader'>
                <p>Processed Carcass Details</p>
            </div>
            <div className='data-content mt-4'>
                <div className='row meatreport-data mt-4'>
                    <p className='col-lg-4 col-md-6 col-12'><span>Total Meat Weight (Kg) :</span><br/> {totalMeatWeight}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Carcass Weight (Kg) :</span><br/> {animalData.meat_details.carcass_weight !== null ? animalData.meat_details.carcass_weight : 0}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Head Weight (Kg) :</span><br/> {animalData.meat_details.head_weight !== null ? animalData.meat_details.head_weight : 0}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Liver/Heart Weight (Kg) :</span><br/> {animalData.meat_details.liver_heart_weight !== null ? animalData.meat_details.liver_heart_weight : 0 }</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Legs Weight (Kg) :</span><br/> {animalData.meat_details.legs}</p>
                    <p className='col-lg-4 col-md-6 col-12'><span>Viscera/Lungs Weight (Kg) :</span><br/> {animalData.meat_details.lungs_weight}</p>
                </div>
                <p className='manager mt-5 mb-4'><span>Meat Processing Manager :</span> {animalData.meat_details.meat_process_manager}</p>
                
            </div>
        </div>
    </div>
   </div>
   <div className='container col-md-12 download-btn mt-5'>
        <button className='btn btn-primary' type="button" onClick={handleDownload}>Download Now</button>           
    </div>
    
   </>
  )
}

export default MeatReport
