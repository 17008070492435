import React from "react";
import "./servicepage.css";
import { Data, siteIndex } from "../../WebsiteData";
import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop/GoToTop";
const UsedData = Data[siteIndex];

const ServicePage = () => {
  return (
    <>
      <div className="container-fluid servicepage">
        <div className="container">
          <div className="row servicepage-content">
            <div className="col-md-6">
              <div className="meat-img">
                <img src={UsedData.productpage.heroImage} alt="Goat Meat" />
              </div>
            </div>
            <div className="col-md-6 servicepage-header-text">
              <p className="text-white">Know Your Meat</p>
              <h1 className="title mt-2">
                Understanding the Goat from Farm to Table
              </h1>

              <p className="paragraph mt-5 text-white">
                Delve into the world of goat meat with our "Know Your Meat"
                section, where we provide detailed insights into every aspect of
                goat production. From the weight of the goat to its unique
                identification number, we offer comprehensive information to
                ensure transparency and traceability from farm to table.
              </p>
              <Link to="/services/knowyourmeat" href="../../Pages/ServicePage/KnowYourMeat.js">
              <button className="col-4 btn btn-light">Click Here</button>
            </Link>
            </div>
          </div>
        </div>
      </div>
      <GoToTop/>
    </>
  );
};

export default ServicePage;
