export const siteIndex = 0;

export const Data = [
  {
    site: {
      url: "https://muktinathlivestockbank.com.np",
      name: "Muktinath Krishi Livestock Bank Limited",
      logo: "/assets/livestock/livestock-logo.png",
    },
    herosection: {
      title: "Welcome to",
      company_name: "Muktinath Livestock Bank Limited",
      description:
        "We are your trusted partner for all your livestock needs. As a subsidiary of Muktinath Krishi Company Ltd., we specialize in serving the dynamic and vital livestock sector. Our comprehensive services are designed to support every aspect of your operation. Whether you’re a small-scale farmer or a large-scale operation, we provide the essential tools and expertise to help you thrive in today’s competitive market.",
      cta: "Explore With Us",
      heroImage: "/assets/livestock/livestock-heroimg.png",
      backgroundImage: "/assets/livestock/livestock-background.png",
    },
    initiationcard: {
      cardImage: "/assets/livestock/slaughter-house.png",
      title: "Slaughter House in Butwal ",
      description:
        "Step into Butwal Slaughterhouse, where every step of the meat processing journey is meticulously crafted with a commitment to safety and ethics. With a focus on humane practices and stringent quality control measures, we strive to earn your trust as the preferred choice for meat processing in Butwal and beyond.",
    },
    productsection: {
      icon: "/assets/livestock/livestock-icon.png",
      description:
        "We offer a diverse range of high-quality solutions to nurture and care for your nursery plants and fruit-bearing trees. From specialized fertilizers and soil amendments to pest control products and pruning tools. Also explore our selection of fruit plants, carefully cultivated to bring you the freshest and most delicious produce straight from your own garden.",
      productdata: [
        {
          seedID: 0,
          seedimage: "/assets/products/power-chaff-cutter.png",
          seedname: "Power Chaff Cutter",
          seedcategory: "Livestock",
          seedprice: "Rs.65,500.00",
          link: "https://www.muktinathkrishi.com/shop/power-chaff-cutter",
        },
        {
          seedID: 1,
          seedimage: "/assets/products/mini-milking-machine.png",
          seedname: "Mini Milking Machine",
          seedcategory: "Livestock",
          seedprice: "Rs.75,000.00",
          link: "https://www.muktinathkrishi.com/shop/mini-milking-machine",
        },
        {
          seedID: 2,
          seedimage: "/assets/products/milk-analyzer.png",
          seedname: "Milk Analyzer",
          seedcategory: "Livestock",
          seedprice: "Rs.95,000.00",
          link: "https://www.muktinathkrishi.com/shop/milk-analyzer",
        },
        {
          seedID: 3,
          seedimage: "/assets/products/manual-cum-power-chaff-cutter.png",
          seedname: "Manual Cum Power Chaff Cutter",
          seedcategory: "Livestock",
          seedprice: "Rs.48,500.00",
          link: "https://www.muktinathkrishi.com/shop/manual-cum-power-chaff-cutter",
        },
        {
          seedID: 4,
          seedimage: "/assets/products/cream-separator.png",
          seedname: "Cream Separator",
          seedcategory: "Livestock",
          seedprice: "Rs.25,000.00",
          link: "https://www.muktinathkrishi.com/shop/cream-seperator",
        },
        {
          seedID: 5,
          seedimage: "/assets/products/cow-mat-plain-edge.png",
          seedname: "Cow Mat(non-interlocked/plain edge)",
          seedcategory: "Livestock",
          seedprice: "Rs.6,500.00",
          link: "https://www.muktinathkrishi.com/shop/cow-matnon-interlockplain-edge",
        },
        {
          seedID: 6,
          seedimage: "/assets/products/cow-mat-interlocked.png",
          seedname: "Cow Mat(interlocked)",
          seedcategory: "Livestock",
          seedprice: "Rs.7,500.00",
          link: "https://www.muktinathkrishi.com/shop/cow-mat-interlocked",
        },
      ],
    },
    productpage: {
      heroImage: "/assets/services/knowyourmeat.png",
    },
    aboutsection: {
      company_name: "At Muktinath Livestock Bank Ltd",
      description:
        " , our passion for Nepal's diversified livestock industry boosts our unwavering commitment to be a reliable partner for livestock farmers, breeders, and agribusinesses nationwide. Rooted in a deep understanding of the sector, our story is one of purpose, expertise, and a vision to revolutionize livestock through modern and advanced solutions, knowledge, and guaranteed support for every stakeholder. We offer a comprehensive package of services, from advanced livestock management software to personalized nutritional consulting, all driven by a commitment to innovation and fostering a collaborative community. Ethical practices and animal welfare are our major priority of everything we do, ensuring the well-being of animals and the environment. Choose Muktinath Livestock Bank for our holistic approach, tailored solutions, expert team, and strong commitment to quality assurance and sustainability.",
      backgroundImage: "/assets/livestock/image1.png",
    },
    servicesection: {
      icon: "/assets/livestock/livestock-icon.png",
      description:
        "At Muktinath Livestock Bank Limited, we offer a comprehensive range of services tailored to meet the diverse needs of livestock owners and farmers. From veterinary care and breeding programs to nutritional consultation and advanced herd management software, we provide holistic solutions to optimize the health, productivity, and profitability of your livestock.",
      servicedata: [
        {
          cardId: 0,
          cardImage: "/assets/services/lms1.jpg",
          cardImageOnHover: "/assets/services/lms2.jpg",
          title: "Livestock Management Software",
          para: "We develop and provide software solutions tailored to manage livestock operations, including tracking animal health, breeding, feeding schedules, and performance metrics. The software service covers all round coverage of all sort of tailored software solutions which are as per the requirement. Our service doesn’t restrict with the individual farmers but we offer software solutions to all sort of organizations, government bodies, NGO/ INGO’s.",
        },
        {
          cardId: 1,
          cardImage: "/assets/services/health-services1.jpg",
          cardImageOnHover: "/assets/services/health-services2.jpg",
          title: "Animal Health Services",
          para: "We offer veterinary care, consulting, laboratory diagnosis, and treatment plans to ensure the health and well-being of livestock.",
        },
        {
          cardId: 2,
          cardImage: "/assets/services/nutritional1.jpg",
          cardImageOnHover: "/assets/services/nutritional2.jpg",
          title: "Nutritional Consulting",
          para: "Provide expertise in formulating balanced diets, nutritional plans and promote fodder production to minimize cost of production for different livestock species according to stages of growth and production.",
        },
        {
          cardId: 3,
          cardImage: "/assets/services/breeding1.jpg",
          cardImageOnHover: "/assets/services/breeding2.jpg",
          title: "Livestock Breeding and Genetics",
          para: "We offer breeding programs, design tailored herd rotation program, artificial insemination equipment, connections with AI technician, decision making for breed selection and maintenance.",
        },
        {
          cardId: 4,
          cardImage: "/assets/services/data-analysis1.jpg",
          cardImageOnHover: "/assets/services/data-analysis2.jpg",
          title: "Livestock Data Analytics",
          para: "We collect and analyze data related to livestock performance, health trends, and market demand to optimize decision-making and improve productivity.",
        },
        {
          cardId: 5,
          cardImage: "/assets/services/sales1.jpg",
          cardImageOnHover: "/assets/services/sales2.jpg",
          title: "Livestock Marketing and Sales",
          para: "We offer assistance to the farmers in marketing their livestock products through connecting them with the market places, animal product industries, cooperatives, NGO/INGOs who are involved in purchase and processing activities. Likewise, we provide data driven decision making for value addition and product diversification.",
        },
        {
          cardId: 6,
          cardImage: "/assets/services/housing1.jpg",
          cardImageOnHover: "/assets/services/housing2.jpg",
          title: "Livestock Housing and Infrastructure",
          para: "We design and construct modern and efficient livestock housing facilities, including barns, sheds, and feeding systems, tailored to the specific needs of different types of livestock, location and climatic condition of the project site.",
        },
        {
          cardId: 7,
          cardImage: "/assets/services/training1.jpg",
          cardImageOnHover: "/assets/services/training2.jpg",
          title: "Training and Education",
          para: "We offer training programs, workshops, and educational resources for farmers and livestock professionals on best practices in animal husbandry, management techniques, and regulatory compliance.",
        },
        {
          cardId: 8,
          cardImage: "/assets/services/rnd1.jpg",
          cardImageOnHover: "/assets/services/rnd2.jpg",
          title: "Research and Development",
          para: "We invest in research and development initiatives to innovate new technologies, products, and practices that enhance productivity, sustainability, and animal welfare in the livestock industry. We move forward with the training and extension of product or technology only after we carry out various R&D activities at our own R&D stations located at Chitwan and Syangja.",
        },
      ],
    },
    servicepage:{
      nepalLogo: "/assets/services/nepal-logo.png",
      mkclLogo: "/assets/services/mkcl-logo.png",
      butwalLogo: "/assets/services/butwal-logo.png",
      heroImage:"/assets/services/slaughter-heroimg.png"
    },
    guaranteesection: {
      guaranteeImage: "/assets/livestock/image3.png",
      guaranteedata: [
        {
          guaranteeId: 0,
          icon: "/assets/livestock/quality-icon.png",
          title: "Quality Assurance Guarantee",
          description:
            "At Muktinath Livestock Bank Ltd., we uphold the highest standards of quality. From meticulously selected breeding stock to the best nutritional supplements and veterinary care, we prioritize the health, well-being, and productivity of your livestock.",
        },
        {
          guaranteeId: 1,
          icon: "/assets/livestock/livestock-icon.png",
          title: "Reliable Support",
          description:
            "Our experienced professionals provide unwavering support throughout your livestock journey. We offer personalized guidance, innovative solutions, and prompt assistance to help you overcome challenges and achieve your goals in the industry.",
        },
        {
          guaranteeId: 2,
          icon: "/assets/livestock/customer-icon.png",
          title: "Customer Satisfaction",
          description:
            "Our experienced professionals provide unwavering support throughout your livestock journey. We offer personalized guidance, innovative solutions, and prompt assistance to help you overcome challenges and achieve your goals in the industry.",
        },
      ],
    },
    gallerysection: {
      gallerydata: [
        {
          galleryId: 0,
          galleryImage: "/assets/livestock/image1.png",
        },
        {
          galleryId: 1,
          galleryImage: "/assets/livestock/image2.png",
        },
        {
          galleryId: 2,
          galleryImage: "/assets/livestock/image3.png",
        },
        {
          galleryId: 3,
          galleryImage: "/assets/livestock/livestock-background.png",
        },
        {
          galleryId: 4,
          galleryImage: "/assets/livestock/image1.png",
        },

        {
          galleryId: 5,
          galleryImage: "/assets/livestock/image2.png",
        },
        {
          galleryId: 6,
          galleryImage: "/assets/livestock/image3.png",
        },
        {
          galleryId: 7,
          galleryImage: "/assets/livestock/livestock-background.png",
        },
        {
          galleryId: 8,
          galleryImage: "/assets/livestock/image1.png",
        },
        {
          galleryId: 9,
          galleryImage: "/assets/livestock/image2.png",
        },
      ],
    },
    footersection: {
      companylogo: "/assets/livestock/livestock-logo.png",
      company_name: "Muktinath Livestock Bank Limited",
      address: "Basundhara, Kathmandu",
      phonenumber: "+977-01-4950097",
      email: "info@muktinathlivestockbank.com.np",
    },
  },
];
